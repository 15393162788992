import { ReactNode } from 'react';

import { ColorsTokens } from '@/styles/theme/colorTokens';

import { RMCheckbox } from '../RMCheckbox/RMCheckbox';
import { RMText } from '../RMText/RMText';

import { PickerItem, PickerItemContent, PickerItemWrapper } from './RMPickerItem.styles';

export interface RMPickerItemProps {
  label: string | ReactNode;
  value: string;
  selected: boolean;
  disabled?: boolean;
  backgroundColor?: keyof typeof ColorsTokens;
  textColor?: keyof typeof ColorsTokens;
  bold?: boolean | 'bold' | 'medium';
  checkboxAlignment?: 'center' | 'top';
  error?: string | null;
  onClick: (value: string) => void;
}

export function RMPickerItem({
  label,
  value,
  selected,
  disabled,
  backgroundColor = 'surface-bright',
  textColor = 'primary',
  bold = true,
  checkboxAlignment = 'center',
  error,
  onClick,
}: RMPickerItemProps) {
  return (
    <PickerItemWrapper>
      <PickerItem
        data-selected={selected}
        data-disabled={disabled}
        data-error={error != null}
        data-checkbox-alignment={checkboxAlignment}
        backgroundColor={backgroundColor}
        onClick={() => {
          if (!disabled) {
            onClick(value);
          }
        }}
      >
        <RMCheckbox checked={selected} disabled={disabled} />
        <PickerItemContent>
          {typeof label === 'string' ? (
            <RMText type="sans" size="xs" bold={bold} color={textColor}>
              {label}
            </RMText>
          ) : (
            label
          )}
        </PickerItemContent>
      </PickerItem>
      {error && (
        <RMText type="sans" size="xs" color="danger">
          {error}
        </RMText>
      )}
    </PickerItemWrapper>
  );
}
